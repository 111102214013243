import * as React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import CartContext, { ICartItem } from '../components/shop/CartContext'
import CtaLink from '../components/CtaLink'
import formatCurrency from '../utils/formatCurrency'
import ShopLayout from '../layouts/Shop'

const StyledCartItemContainer = styled.div`
  display: flex;
`
const StyledAmountInput = styled.input`
  width: 50px;
`
const StyledProductLink = styled(Link)`
  font-size: 18px;
  font-weight: bold;
`
const StyledRemoveButton = styled.button`
  background-color: none;
  border: 0;
`

interface ICartItemProps {
  cartItem: ICartItem
  product: any
}
function CartItem({ product, cartItem }: ICartItemProps) {
  const cartContext = React.useContext(CartContext)
  return (
    <tr>
      <td>
        <div>
          <StyledProductLink to={`/shop/${cartItem.slug}`}>
            {product.name} {!product.priceExact && <>ca. </>}
            {product.amount}
          </StyledProductLink>
        </div>
        <div>
          Menge:
          <StyledAmountInput
            type="number"
            value={cartItem.amount}
            onChange={e => {
              cartContext.updateCart(product.slug, parseInt(e.target.value, 10))
            }}
          />
          <StyledRemoveButton
            onClick={e => {
              cartContext.removeFromCart(product.slug)
            }}
          >
            löschen
          </StyledRemoveButton>
        </div>
      </td>
      <td>
        Preis: {!product.priceExact && 'ca. '}
        {formatCurrency(cartItem.amount * product.price)}
        {product.pfand && <> + {formatCurrency(cartItem.amount * 0.3)} Pfand</>}
      </td>
    </tr>
  )
}

const Shop = props => {
  const cartContext = React.useContext(CartContext)
  const products = props.data.allProductsJson.edges.map(p => p.node)

  let sum = 0
  let isPriceExact = true

  return (
    <ShopLayout>
      <Page>
        <Container>
          <h1>Warenkorb</h1>
          {!cartContext.cart.length && <p>keine Produkte im Warenkorb</p>}
          <table>
            {cartContext.cart.map(cartItem => {
              const product = products.find(p => p.slug === cartItem.slug)
              if (!product) return null
              sum += cartItem.amount * (product.price + (product.pfand ? 0.3 : 0))
              if (!product.priceExact) isPriceExact = false
              return <CartItem key={product.slug} product={product} cartItem={cartItem} />
            })}
            {!!cartContext.cart.length && (
              <tr>
                <td></td>
                <td>
                  Summe: {!isPriceExact && 'ca. '}
                  {formatCurrency(sum)}
                </td>
              </tr>
            )}
          </table>
          <CtaLink to={`/kasse`}>» zur Kasse</CtaLink>
        </Container>
      </Page>
    </ShopLayout>
  )
}

export default Shop

export const query = graphql`
  query ShopCart {
    allProductsJson {
      edges {
        node {
          name
          amount
          slug
          price
          priceExact
          pfand
        }
      }
    }
  }
`
